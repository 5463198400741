<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" ref="searchForm" :model="searchForm" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">合同名称</span>
          </span>
          <el-input v-model="searchForm.name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">合同编号</span>
          </span>
          <el-input v-model="searchForm.code" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">合同状态</span>
          </span>
          <el-select v-model="searchForm.status" placeholder="请输入" clearable>
            <el-option v-for="(item, index) in contractStatus" :key="index" :label="item.label"
              :value="Number(item.value)" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">合同类型</span>
          </span>
          <el-select v-model="searchForm.type" placeholder="请输入" clearable>
            <!-- <el-option label="全部" value="null" /> -->
            <el-option v-for="(item, index) in contractType" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">甲方</span>
          </span>
          <el-input v-model="searchForm.insuranceCompany" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">乙方</span>
          </span>
          <el-input v-model="searchForm.insuredOrg" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="searchBtn" @click="searchOnRefer">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-button type="success" size="small" v-focus @click="addDj">新增</el-button>
      <template v-if="contractTotal != 0">
        <el-table :data="tableData" v-loading="tableLoading" ref="multipleTable" :height="$store.state.tabHeight-65"
          style="width: 100%; margin-top: 20px; margin-bottom: 20px">
          <el-table-column type="index" label="序号" width="50px" />
          <el-table-column align="center" prop="name" label="合同名称" min-width="150px" />
          <el-table-column align="center" prop="code" label="合同编号" min-width="150px" />
          <el-table-column align="center" prop="type" label="合同类型" width="150px">
            <template slot-scope="scope">
              {{
                  contractType.find((item) => item.value === scope.row.type)
                    ? contractType.find((item) => item.value === scope.row.type)
                        .label
                    : ''
                }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="insuranceCompany" label="甲方" min-width="150px" />
          <el-table-column align="center" prop="insuredOrg" label="乙方" min-width="150px" />
          <el-table-column align="center" prop="fileUrl" label="合同文件" width="100px">
            <template slot-scope="scope">
              <el-button v-if="scope.row.fileUrl" type="text" @click="download(scope.row.fileUrl)">下载
              </el-button>
              <span v-else> - </span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="startTime" label="合同开始时间" min-width="150px" />
          <el-table-column align="center" prop="endTime" label="合同结束时间" min-width="150px" />
          <el-table-column align="center" prop="status" label="状态" width="150px">
            <template slot-scope="scope">
              <span :class="`txtColor${scope.row.status}`">
                {{
                contractStatus.find((item) => item.value == scope.row.status)
                  .label ?? ''
              }}
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="days" label="过期天数" width="150px">
            <template slot-scope="scope">
              {{ scope.row.days > 999 ? '999+' : scope.row.days }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="createUserName" label="创建人" />
          <el-table-column fixed="right" align="center" prop="address" label="操作" width="300px">
            <template slot-scope="scope">
              <el-button @click="check(scope.row)" v-focus type="primary" size="small">查看
              </el-button>
              <el-button @click="del(scope.row)" v-focus type="primary" size="small">电梯明细
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="contractTotal" :page.sync="searchForm.current" :limit.sync="searchForm.size"
          @pagination="contractPage" />
      </template>
      <div class="nullDate" v-else v-loading="tableLoading">
        <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
      </div>
    </div>

    <el-dialog :title="tit" v-if="dialogVisible" :visible.sync="dialogVisible" :close-on-click-modal="false"
      width="950px" top="20vh">
      <div style="height: 40vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
        <el-form label-position="right" label-width="120px" ref="contractForm" :model="contractForm" :rules="formRules">
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="合同电梯:" prop="elevator_name">
                <el-input :placeholder="contractForm.elevator_name || '暂无内容'" v-model="contractForm.elevator_name"
                  class="wid90" disabled>
                  <el-button :disabled="contractFormDisabled" slot="append" type="primary"
                    @click="selectElevator()">选择电梯</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="合同名称:" prop="name">
                <el-input class="wid90" v-model="contractForm.name" placeholder="请输入"
                  :disabled="contractFormDisabled" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="合同编号:" prop="code">
                <el-input class="wid90" v-model="contractForm.code" placeholder="请输入"
                  :disabled="contractFormDisabled" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="合同类型:" prop="type">
                <el-select v-model="contractForm.type" placeholder="请输入" style="width: 100%"
                  :disabled="contractFormDisabled">
                  <el-option v-for="(item, index) in contractType" :key="index" :label="item.label"
                    :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="甲方:" prop="firstParty">
                <el-select v-model="contractForm.firstParty" placeholder="请输入" style="width: 100%"
                  :disabled="contractFormDisabled" filterable clearable>
                  <el-option v-for="(item, index) in tenementName" :key="index" :label="item.name" :value="item.id" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="乙方:" prop="secondParty">
                <el-select v-model="contractForm.secondParty" placeholder="请输入" style="width: 100%"
                  :disabled="contractFormDisabled" filterable clearable>
                  <el-option v-for="(item, index) in insuranceName" :key="index" :label="item.name" :value="item.id" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="合同开始时间:" prop="startTime">
                <el-date-picker v-model="contractForm.startTime" style="width: 100%" type="date" placeholder="请输入"
                  value-format="yyyy-MM-dd HH:mm:ss" :disabled="contractFormDisabled" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="合同结束时间:" prop="endTime">
                <el-date-picker v-model="contractForm.endTime" style="width: 100%" type="date" placeholder="请输入"
                  value-format="yyyy-MM-dd HH:mm:ss" :disabled="contractFormDisabled" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="24">
              <el-form-item label="附件:" prop="fileUrl">
                <div v-if="!contractFormDisabled">
                  <FileUpload v-if="!contractFormDisabled" :md5s.sync="contractForm.fileUrl" :multiple="false"
                    :limit="1" :imgList="['pdf']" :url="url" :fileSize="30" />
                  <div v-if="!contractFormDisabled" :style="(contractForm.fileUrl ?  'margin-left:0px;' :'margin-left:120px;')+'font-size:12px'">
                    仅支持pdf文件格式，单文件不超过30MB
                  </div>
                </div>
                <template v-else>
                  <el-image v-if="
                  		contractForm.fileUrl &&
                  		(contractForm.fileUrl.indexOf('.jpg') != -1 ||
                  			contractForm.fileUrl.indexOf('.png') != -1 ||
                  			contractForm.fileUrl.indexOf('.jpeg') != -1)
                  		" style="width: 150px; height: 150px" :src="contractForm.fileUrl" :fit="'contain'" />
                  <!-- <p v-else @click="openFile(contractForm.fileUrl )">{{ contractForm.fileUrl }}</p> -->
                  <el-button type="primary" v-else-if="contractForm.fileUrl"
                    @click="openFile(contractForm.fileUrl )">下载合同</el-button>
                  <p v-else style="line-height: 10px;">--</p>
                </template>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" v-if="!contractFormDisabled" type="primary" :loading="loading" @click="dioSub">确
          定</el-button>
        <el-button size="small" v-if="!contractFormDisabled" @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <SelectElevator v-if="selectOtherDialogVisible" ref="SelectElevator" @transferElevator="selectOtherResult" />
    <ElevatorInfo v-if="elevatorInfoDialogVisible" ref="ElevatorInfo" />
  </div>
</template>
<script>
  import Pagination from '../../../components/Pagination'
  import FileUpload from '../../../components/FileUploadPWP'
  import SelectElevator from './components/selectElevator'
  import ElevatorInfo from './components/elevatorInfo'
  import {
    getTypeOptions
  } from '../../../components/useEscape';
  export default {
    components: {
      Pagination,
      FileUpload,
      SelectElevator,
      ElevatorInfo,
    },
    data() {
      var validateFirstParty = (rule, value, callback) => {
        const regular = /^[\u4e00-\u9fa5_a-zA-Z0-9]{2,20}$/
        if (value == null || value === '') {
          callback(new Error('甲方不能为空!'))
        } else if (value && !regular.test(value)) {
          callback(
            new Error('必须是中文，字母，数值，不可输入特殊符号，长度2-20')
          )
        }
        callback()
      }
      var validateSecondParty = (rule, value, callback) => {
        const regular = /^[\u4e00-\u9fa5_a-zA-Z0-9]{2,20}$/
        if (value == null || value === '') {
          callback(new Error('乙方不能为空!'))
        } else if (value && !regular.test(value)) {
          callback(
            new Error('必须是中文，字母，数值，不可输入特殊符号，长度2-20')
          )
        }
        callback()
      }
      return {
        // 表格参数
        searchForm: {
          current: 1,
          size: 10,
        },
        contractForm: {
          eleIds: '',
          elevator_name: '',
          name: '',
          code: '',
          type: '',
          startTime: '',
          endTime: '',
          firstParty: '',
          secondParty: '',
        },
        // 合同类型
        contractStatus: [],
        // 合同类型
        contractType: [{
            label: '物业合同',
            value: 'TENEMENT_CONTRACT',
          },
          {
            label: '维保合同',
            value: 'MAINTAIN_CONTRACT',
          },
        ],
        tableData: [],
        // 分页参数
        contractTotal: 0,
        // dialog参数
        tit: '',
        dialogVisible: false,
        loading: false,
        url: '/api/file/web/uploadByOSS', // 上传地址
        formRules: {
          elevator_name: [{
            required: true,
            message: '电梯名称不能为空',
            trigger: 'blur'
          }, ],
          name: [{
            required: true,
            message: '合同名称必须是中文，字母，数值，特殊符号，长度2-20',
            trigger: 'blur',
            min: 2,
            max: 20,
          }, ],
          code: [{
            required: true,
            message: '合同编号必须是中文，字母，数值，特殊符号，长度2-20',
            trigger: 'blur',
            min: 2,
            max: 20,
          }, ],
          type: [{
            required: true,
            message: '合同类型不能为空',
            trigger: 'blur'
          }, ],
          startTime: [{
            required: true,
            message: '合同开始时间不能为空',
            trigger: 'blur'
          }, ],
          endTime: [{
            required: true,
            message: '合同结束时间不能为空',
            trigger: 'blur'
          }, ],
          firstParty: [{
            required: true,
            validator: validateFirstParty,
            trigger: 'blur'
          }, ],
          secondParty: [{
            required: true,
            validator: validateSecondParty,
            trigger: 'blur'
          }, ],
        },
        selectOtherDialogVisible: false, // 选择电梯
        elevatorInfoDialogVisible: false, // 电梯明细
        contractFormDisabled: false, // 查看禁点
        selectData: [],

        tenementName: [], // 甲方
        insuranceName: [], // 乙方

        tableLoading: true
      }
    },
    filters: {},
    methods: {
      //
      openFile(url) {
        window.open(url, '_blank');
      },
      // 表格下载
      download(url) {
        const arr = url.split('.')
        if (arr[arr.length - 1] == 'txt') {
          console.log(arr[arr.length - 1], 'arr[arr.length - 1]');
          const urlArr = url.split('.com/')
          this.$http.get(`/api/file/web/oss/downloadFile?bucketName=zx-dev-video&objectName=${ urlArr[1]}`).then((
            res) => {
            let blob = new Blob([res.data], {
              type: getTypeOptions(arr[arr.length - 1])
            });
            let url = window.URL.createObjectURL(blob);
            const link = document.createElement('a'); //创建a标签
            link.href = url;
            // link.target = "_blank";
            link.download = `合同附件.${arr[arr.length - 1]}`; //重命名文件
            link.click();
            window.URL.revokeObjectURL(url);
            // console.log("下载文件" + res);
          })
        } else {
          window.open(url, '_blank');
        }
      },
      getFileName(url) {
        var num = url.lastIndexOf('/') + 1
        var fileName = url.substring(num)
        //把参数和文件名分割开
        fileName = decodeURI(fileName.split('?')[0])
        return fileName
      },

      download(href, name) {
        let eleLink = document.createElement('a')
        eleLink.download = name
        eleLink.href = href
        eleLink.click()
        eleLink.remove()
      },
      downloadUrlFile(url) {
        url = url.replace(/\\/g, '/')
        const xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.responseType = 'blob'
        //xhr.setRequestHeader('Authorization', 'Basic a2VybWl0Omtlcm1pdA==');
        xhr.onload = () => {
          if (xhr.status === 200) {
            // 获取文件blob数据并保存
            var fileName = this.getFileName(url)
            this.saveAs(xhr.response, fileName)
          }
        }

        xhr.send()
      },
      saveAs(data, name) {
        var urlObject = window.URL || window.webkitURL || window
        var export_blob = new Blob([data])
        var save_link = document.createElementNS(
          'http://www.w3.org/1999/xhtml',
          'a'
        )
        save_link.href = urlObject.createObjectURL(export_blob)
        save_link.download = name
        save_link.click()
      },
      // 获取字典数据
      getDictionar(code) {
        // code = code.join()
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {

            if (res.data.code === 200) {
              const {
                data: {
                  data
                },
              } = res
              this.contractStatus = data.CONTRACT_STATUS
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },

      // 获取维保单位
      getTenement() {
        const data = {
          current: 1,
          size: 9999,
          type: "TENEMENT_UNIT,PROPERTY_UNIT"
          // 'TENEMENT_UNIT',
        }
        this.$http.post('/api/system/web/org/list', data).then((res) => {

          if (res.data.success) {
            const {
              data: {
                data
              },
            } = res
            // PROPERTY_UNIT
            this.tenementName = data.records ?? []
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },

      // 获取保险单位
      getInsurance() {
        const data = {
          current: 1,
          size: 9999,
          type: "TENEMENT_UNIT,MAINTAIN_UNIT"
          // 'INSURANCE_UNIT',
        }
        this.$http.post('/api/system/web/org/list', data).then((res) => {

          if (res.data.success) {
            const {
              data: {
                data
              },
            } = res
            this.insuranceName = data.records ?? []
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      // 分页
      contractPage(data) {
        this.searchForm.current = data.page
        this.searchForm.size = data.limit
        this.onRefer()
      },
      // 选择电梯
      selectElevator() {
        console.log('选择电梯', this.selectData)
        this.selectOtherDialogVisible = true
        this.$nextTick(() => {
          this.$refs.SelectElevator.init(
            this.contractForm.eleIds,
            this.selectData,
            (refresh) => {
              if (refresh) {}
            }
          )
        })
      },
      // 选择电梯返回
      selectOtherResult(data) {
        console.log('选择电梯返回', data)
        const nameArr = []
        const idArr = []
        data.map((item) => {
          nameArr.push(item.name)
          idArr.push(item.id)
        })
        this.selectData = data
        this.selectOtherDialogVisible = false
        this.$set(this.contractForm, 'elevator_name', nameArr.join())
        this.contractForm.eleIds = idArr
      },
      // 弹出框提交
      dioSub() {
        this.loading = true
        console.log(this.contractForm, 'this.contract')
        this.$refs.contractForm.validate(async (valid) => {
          if (valid) {
            console.log(this.contractForm, 'this.contract')
            const data = JSON.parse(JSON.stringify(this.contractForm))
            data.startTime = new Date(
              new Date(Date.parse(this.contractForm.startTime)).getTime() +
              8 * 60 * 60 * 1000
            )

            data.endTime = new Date(
              new Date(Date.parse(this.contractForm.endTime)).getTime() +
              8 * 60 * 60 * 1000
            )

            const time = data.endTime - data.startTime
            if (time <= 0) {
              this.$message.error('合同结束时间必须晚比合同开始时间')
              return ''
            }
            this.$http.post('/api/ele/web/contract/submit', data).then((res) => {
              console.log(res, '弹出框提交')
              if (res.data.success) {
                this.dialogVisible = false
                this.selectData = []
                this.onRefer()
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            })
          }
        })
        this.loading = false
      },
      // 搜索
      searchOnRefer() {
        const regExp = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g
        if (regExp.test(this.searchForm.code)) {
          this.$message.error('标题仅可输入字母，数值，特殊符号!')
          return
        }
        this.searchForm.current = 1
        this.onRefer()
      },
      // 重置
      reset() {

        this.searchForm = {
          current: 1,
          size: 10,
        }
        this.onRefer()
      },
      // 获取合同方法
      onRefer() {
        const data = JSON.parse(JSON.stringify(this.searchForm))
        data.selectType = 3
        this.tableLoading = true
        this.$http.post('/api/ele/web/contract/list', data).then((res) => {

          if (res.data.success) {
            const {
              data: {
                data
              },
            } = res
            this.tableData = data.records ?? []
            this.contractTotal = data.total ?? 0
            this.$nextTick(() => {
              if (this.contractTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          } else {
            this.$message.error(res.data.msg)
          }
          this.tableLoading = false
        })
      },
      // 查看合同
      check(item) {
        this.tit = '查看合同'
        this.dialogVisible = true
        this.contractFormDisabled = true
        const data = {
          id: item.id,
        }
        this.$http
          .get(`/api/ele/web/contract/detail?id=${item.id}`)
          .then((res) => {

            if (res.data.success) {
              const {
                data: {
                  data
                },
              } = res
              item.elevator_name = data.eleNames.join()
              console.log(data.eleNames.join(), 'data.eleNames.join')
              this.contractForm = JSON.parse(JSON.stringify(item))
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      // 查看电梯明细
      del(item) {
        console.log(item)
        this.elevatorInfoDialogVisible = true
        this.$nextTick(() => {
          this.$refs.ElevatorInfo.init(item.id, (refresh) => {
            if (refresh) {}
          })
        })
      },
      addDj() {
        this.tit = '新增合同'
        this.dialogVisible = true
        this.contractFormDisabled = false
        this.selectData = []
        this.contractForm = {}
      },
    },
    created() {
      this.getDictionar(['CONTRACT_TYPE', 'CONTRACT_STATUS'])
      // this.getDictionar('合同状态', 'CONTRACT_STATUS')
    },
    mounted() {
      this.$nextTick(() => {
        this.onRefer()
        this.getTenement()
        this.getInsurance()
      })
    },
  }
</script>
<style lang="scss" scoped>
  .searchBtn {
    margin-left: 15px;
  }

  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .txtColor0 {
    color: #909399;
  }

  .txtColor1 {
    color: #409eff;
  }

  .txtColor2 {
    color: #f56c6c;
  }
</style>
