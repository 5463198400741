<template>
  <el-dialog
    :title="planInfo && planInfo.id ? '编辑维保计划' : '新增维保计划'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    append-to-body
    width="70%"
    top="8vh"
    center
    @close="addPlanDialogClose"
  >
    <div
      style="height: 70vh; overflow-y: scroll; padding-top: 10px; padding-right: 20px"
      class="scrollElement"
    >
      <el-form
        class="addPlanForm"
        ref="addPlanForm"
        :model="addPlanForm"
        :rules="addPlanRules"
        label-width="150px"
        label-position="right"
      >
        <div class="grid-content bg-purple">
          <el-form-item label="维保计划名称:" prop="planName">
            <el-input v-model.trim="addPlanForm.planName" placeholder="请输入维保计划名称" type="text" />
          </el-form-item>
          <el-form-item label="选择电梯:" prop="elevator_name">
            <el-input :placeholder="addPlanForm.elevator_name || '暂无内容'" v-model="addPlanForm.elevator_name"
              :title="addPlanForm.elevator_name" class="input-with-select" disabled>
              <el-button slot="append" type="primary" :disabled="checkType == 'edit'"
                @click="selectElevator()">选择电梯</el-button>
            </el-input>
          </el-form-item>
          <el-form-item
            label="首次维保日期:"
            prop="firstMaintainTime"
          >
            <el-date-picker
              style="width: 90%"
              v-model="addPlanForm.firstMaintainTime"
              :picker-options="pickerOptions"
              type="date"
              format="yyyy 年 MM 月 dd 日"
              placeholder="请选择首次维保日期"
            />
            <el-tooltip class="item" effect="dark" placement="top-end">
              <div slot="content" style="width: 227px;">
                后续任务按时间间隔依次安排。半月保默认间隔15天，月度保间隔30天，季度保间隔90天，半年保间隔180天，年度保间隔360天。可在下一步进行手动调整每个任务具体日期。
              </div>
              <i class="el-icon-warning-outline" style="margin-left: 10px;"></i>
            </el-tooltip>
          </el-form-item>
          <div class="maintenanTypeLabel">
            <div class="InfoLabel">
              <span style="color: #f56c6c;margin-right: 5px;">*</span>
              <span>选择维保项及次数:</span>
            </div>
            <div>
              <span>（日期相同时，优先安排高等级维保类型，总次数不变。）</span>
            </div>
          </div>
          <div v-for="(item, index) in maintenanType" :key="item.value" class="maintenanList">
            <el-form-item label="" label-width="60px" :key="index" style="margin-bottom: 0">
              <div class="isDisplay" style="margin: 0px 0 5px">
                <el-checkbox v-model="addPlanForm.maintenanItemData[index].type" @change="changeType(index)"
                  style="width: 8%; line-height: 40px">{{ item.label }}</el-checkbox>
                <el-form-item style="width: 40%; margin: 10px 0"
                  :prop="'maintenanItemData.' + index + '.maintainItemId'" :rules="[{
                      required: addPlanForm.maintenanItemData[index].type,
                      message: '请选择维保项',
                      trigger: 'change'}]">
                  <el-select v-model="addPlanForm.maintenanItemData[index].maintainItemId"
                    :disabled="!addPlanForm.maintenanItemData[index].type" placeholder="请选择维保项" clearable filterable
                    style="width: 90%; margin: 0 15px">
                    <el-option
                      v-for="maintenanItem in maintenanItemList.filter(maintenanItem => maintenanItem.type == item.value)"
                      :key="maintenanItem.id" :label="maintenanItem.name" :value="maintenanItem.id" />
                  </el-select>
                </el-form-item>
                <el-form-item style="width: 25%; margin: 10px 20px" :prop="'maintenanItemData.' + index + '.num'"
                  :rules="[{
                    required: addPlanForm.maintenanItemData[index].type,
                    validator: nameVolid,
                    trigger: 'change',
                    }]">
                  <el-input v-model.trim="addPlanForm.maintenanItemData[index].num"
                    :disabled="!addPlanForm.maintenanItemData[index].type" type="text" placeholder="请输入维保次数"
                    style="width: 100%;" class="maintenanNumber">
                    <span slot="append" style="width: 30px">次</span>
                  </el-input>
                </el-form-item>
                <el-radio v-model="addPlanForm.isFirst" :disabled="!addPlanForm.maintenanItemData[index].type"
                  style="width: 15%; line-height: 40px;" :label="index + 1">
                  首次维保类型
                </el-radio>
                <el-tooltip class="item" effect="dark" placement="top-end">
                  <div slot="content" style="width: 227px;">
                    勾选后， 首次维保将安排该类型的维保类型。下一步可以手动修改。未选择时，默认首次维保为最低等级维保类型。
                  </div>
                  <i class="el-icon-warning-outline" style="margin-left: 10px;"></i>
                </el-tooltip>
              </div>
            </el-form-item>
          </div>

        </div>
        <div style="text-align: center;margin-top: 15px;">
          <el-button
            type="primary"
            @click="addPlanConfirm"
          >下一步</el-button>
          <el-button
            type="info"
            @click="addPlanDialogClose"
          >取消</el-button>
        </div>
      </el-form>
      <!-- 选择电梯 -->
      <SelectElevatorAll v-if="selectElevatorVisible" :elevatorId="elevatorId" :initArr="initArr"
        ref="SelectElevatorDialog" @transferElevator="selectElevatorResults" />
      <NextPlan v-if="nextPlanVisible" :planFormData="planFormData" ref="nextPlanDialog"
        @nextPlanClose="nextPlanClose" />
    </div>
    <div slot="footer" class="dialog-footer">
    </div>
  </el-dialog>
</template>

<script>
  import SelectElevatorAll from './selectElevatorAll'
  import NextPlan from './nextPlan.vue'

  export default {
    components: {
      SelectElevatorAll,
      NextPlan
    },
    props: ['planInfo','checkType'],

    data() {
      return {
        loading: true,
        visible: true,
        selectElevatorVisible: false,
        nextPlanVisible: false,
        elevatorId: [],
        initArr: [],
        maintenanType: [{
          value: 1,
          label: '半月保'
        }, {
          value: 2,
          label: '月度保'
        }, {
          value: 3,
          label: '季度保'
        }, {
          value: 5,
          label: '半年保'
        }, {
          value: 4,
          label: '年度保'
        }],
        maintenanItemList: [], // 维保项列表
        planFormData: {}, // 输入表单信息

      pickerOptions: {
        disabledDate(time) {
          // return time.getTime() > new Date(formatDate(new Date(), "yyyy-MM-dd") + " 00:00:00").getTime();
          return time.getTime() < Date.now()
        },
      },


        // 表格参数
        addPlanForm: {
          planName: '', // 计划名称
          eleIds: '', // 电梯id
          elevator_name: '', // 电梯名称
          firstMaintainTime: '', // 开始时间
          isFirst: '',
          maintenanItemData: [{
              type: false,
              maintainItemId: '',
              num: '',
              name: '半月保',
              value: 1
            },
            {
              type: false,
              maintainItemId: '',
              num: '',
              name: '月度保',
              value: 2
            },
            {
              type: false,
              maintainItemId: '',
              num: '',
              name: '季度保',
              value: 3
            },
            {
              type: false,
              maintainItemId: '',
              num: '',
              name: '半年保',
              value: 5
            },
            {
              type: false,
              maintainItemId: '',
              num: '',
              name: '年度保',
              value: 4
            },
          ]
        },
        addPlanRules: {
          planName: [{
            required: true,
            message: '可输入中文，大小写字母，特殊符号且长度为4至20',
            min: 4,
            max: 20,
            trigger: 'blur',
          }, ],
          elevator_name: [{
            required: true,
            message: '请选择电梯',
            trigger: 'blur',
          }, ],
          firstMaintainTime: [{
            required: true,
            message: '请选择首次维保日期',
            trigger: 'blur',
          }, ],
        },
      }
    },

    created() {
      this.getList()
      this.getNotice('MAINTAIN_TYPE')
    },
    mounted() {
      if (this.planInfo.id) {
        this.addPlanForm.id = this.planInfo.id
        this.addPlanForm.planName = this.planInfo.planName
        this.addPlanForm.eleIds = this.planInfo.eleIds
        this.addPlanForm.elevator_name = this.planInfo.eleName
        this.addPlanForm.firstMaintainTime = this.planInfo.firstMaintainTime
        let planDetail = JSON.parse(this.planInfo.planDetail)
        for (const key in planDetail) {
          if (planDetail[key].isFirst && planDetail[key].num > 0) {
            this.addPlanForm.isFirst = Number(key)
          }
        }
        this.addPlanForm.maintenanItemData.map(item => {
          item.type = planDetail[item.value].num && planDetail[item.value].num > 0
          item.maintainItemId = planDetail[item.value].num ? planDetail[item.value].maintainItemId : ''
          item.num = planDetail[item.value].num ? planDetail[item.value].num : ''
        })
      } else {
        this.addPlanForm = {
          planName: '', // 计划名称
          eleIds: '', // 电梯id
          elevator_name: '', // 电梯名称
          firstMaintainTime: '', // 开始时间
          isFirst: '',
          maintenanItemData: [{
              type: false,
              maintainItemId: '',
              num: '',
              name: '半月保',
              value: 1
            },
            {
              type: false,
              maintainItemId: '',
              num: '',
              name: '月度保',
              value: 2
            },
            {
              type: false,
              maintainItemId: '',
              num: '',
              name: '季度保',
              value: 3
            },
            {
              type: false,
              maintainItemId: '',
              num: '',
              name: '半年保',
              value: 5
            },
            {
              type: false,
              maintainItemId: '',
              num: '',
              name: '年度保',
              value: 4
            },
          ]
        }
      }
    },

    methods: {
      getNotice(code) {
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {
            if (res.data.code === 200) {
              const {
                data: {
                  data
                },
              } = res
              this.addPlanForm.maintenanItemData.map(item => {
                if (data.MAINTAIN_TYPE.find(dataItem => dataItem.label == item.name)) {
                  item.value = data.MAINTAIN_TYPE.find(dataItem => dataItem.label == item.name).value
                }
              })
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      nameVolid(rule, value, callback) {
        let reg = /^[0-9]*$/
        if (rule.required && (!reg.test(value) || value == 0 || value > 50) ) {
          return callback(Error('维保次数必须是大于0且小于50的整数'))
        }
        callback()
      },

    // 获取维保项类目
    getList() {
      this.maintenanItemList=[]
      const data = {
        current: 1,
        size: 999,
        isActive: 1,
      }
      this.$http.post('/api/ele/web/maintainItem/getList', data).then((res) => {
        if (res.data.success) {
          const {
            data: { data },
          } = res
          this.maintenanItemList = data.records ?? []
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 选择维保项类型
    changeType(index){
      if (this.addPlanForm.isFirst === '') {
        this.addPlanForm.isFirst = index + 1
      } else {
        if (!this.addPlanForm.maintenanItemData[index].type) {
          let itemData = this.addPlanForm.maintenanItemData.findIndex(item => item.type)
          if (itemData > -1) {
            this.addPlanForm.isFirst = itemData + 1
          } else {
            this.addPlanForm.isFirst = ''
          }
        }
      }
    },
    
    // 选择电梯
    selectElevator() {
      this.selectElevatorVisible = true
    },

    // 选择电梯回调
    selectElevatorResults(data) {
      const nameArr = []
      const idArr = []
      data.map((item) => {
        nameArr.push(item.name)
        idArr.push(item.id)
      })
      // this.selectData = data
      this.selectElevatorVisible = false
      this.$set(this.addPlanForm, 'elevator_name', nameArr.join())
      this.initArr = data
      this.elevatorId = idArr
      this.addPlanForm.eleIds = idArr
    },
    // 保存计划
    async addPlanConfirm() {
      if (!this.addPlanForm.maintenanItemData.find(item => item.type)) {
        this.$message.error('请选择一个维保类型')
        return
      }
      this.$refs.addPlanForm.validate(async (valid) => {
        if (valid) {
          if (!this.addPlanForm.isFirst) {
            let newMaintenanItemData = JSON.parse(JSON.stringify(this.addPlanForm.maintenanItemData)).pop()
            this.addPlanForm.isFirst = newMaintenanItemData.value
          }
          this.planFormData = JSON.parse(JSON.stringify(this.addPlanForm))
          this.nextPlanVisible = true
        }
      })
    },

      nextPlanClose(type) {
        this.nextPlanVisible = false
        if (type) {
          this.addPlanDialogClose()
        }
      },



      addPlanDialogClose() {
        this.visible = false
        this.$emit('closeDialog')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .maintenanTypeLabel {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 40px;

    .InfoLabel {
      width: 138px;
      text-align: right;
      padding-right: 12px;
    }
}
/deep/.maintenanNumber .el-input-group__append{
  padding: 0 10px;
}
/deep/.maintenanList .el-input-number__decrease{
  width: 30px;
}

  /deep/.el-radio__label {
    color: #C0C4CC;
  }

  /deep/.el-radio__input.is-disabled+span.el-radio__label {
    color: #606266;
  }

  .isDisplay {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .addPlanForm {
    padding-right: 35px;
  }

  /deep/.el-form-item {
    margin-bottom: 32px;
  }

  /deep/.el-input-group__prepend {
    width: 40%;
  }

  >>>.el-input-group__append {
    background: #409eff;
    color: #fff;
  }

  >>>.el-input-group__prepend {
    width: 25%;
    padding: 0px;
    background: #409eff;

    .el-select {
      margin: -1px;

      .el-input__inner {
        color: #fff !important;
      }

      ::-webkit-input-placeholder {
        color: #fff;
      }
    }
  }

  .datepicker {
    >>>.el-form-item__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
</style>
