<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">计划名称</span>
          </span>
          <el-input v-model="searchForm.planName" placeholder="请输入计划名称" clearable />
        </el-form-item>
        <!-- <el-form-item>
          <span slot="label">
            <span class="lab-span" >电梯名称</span>
          </span>
          <el-input v-model="searchForm.eleName" placeholder="请输入电梯名称" clearable></el-input>
        </el-form-item> -->
        <el-form-item>
          <span slot="label">
            <span class="lab-span">维保类型</span>
          </span>
          <el-select v-model="searchForm.maintainType" placeholder="请选择维保类型" clearable>
            <el-option v-for="(item, index) in maintainType" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">维保时间</span>
          </span>
          <el-date-picker v-model="searchForm.updateDat" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">小区名称</span>
          </span>
          <el-input v-model="searchForm.plotName" placeholder="请输入小区名称" clearable />
        </el-form-item>
        <!-- <el-form-item>
          <span slot="label">
            <span class="lab-span">创建人</span>
          </span>
          <el-input v-model="searchForm.createUserName" placeholder="请输入创建人" clearable />
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" class="searchBtn" @click="searchOnRefer">搜索</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-button v-focus type="success" size="small" @click="addInfo">新增</el-button>
      <el-button v-focus type="danger" size="small" @click="delAll">批量删除</el-button>
      <template v-if="noticeTotal != 0">
        <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData" :height="$store.state.tabHeight"
          @selection-change="handleSelectionChange" style="width: 100%; margin-top: 20px; margin-bottom: 20px">
          <el-table-column type="selection" width="55px" :selectable="selectable">
            <!-- v-if="tableData[index].maintainContractId != ''" -->
          </el-table-column>
          <el-table-column type="index" label="序号" width="50px" />
          <el-table-column align="center" prop="planName" label="计划名称" width="250px" />
          <el-table-column align="center" label="合同编号" width="250px">
            <template slot-scope="scope">
              {{scope.row.maintainContractCode ? scope.row.maintainContractCode : '--'}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="eleName" label="电梯名称" min-width="250px" />
          <el-table-column align="center" prop="plotName" label="小区名称" width="150px" />
          <el-table-column align="center" prop="maintainTypeName" label="维保类型" width="100px" />
          <el-table-column align="center" prop="startTime" label="开始时间" width="150px" />
          <el-table-column align="center" prop="endTime" label="结束时间" width="150px" />
          <!-- <el-table-column align="center" label="合同编号" width="250px">
            <template slot-scope="scope">
              {{scope.row.maintainContractCode ? scope.row.maintainContractCode : '--'}}
            </template>
          </el-table-column> -->
          <el-table-column align="center" prop="createTime" label="创建时间" width="150px" />
          <el-table-column align="center" prop="createUserName" label="创建人" min-width="150px" />
          <el-table-column align="center" fixed="right" prop="address" label="操作" width="300px">
            <template slot-scope="scope">
              <!-- <el-button
                @click="check(scope.row)"
                type="primary"
                size="small"
              >电梯明细
              </el-button> -->
              <el-button v-focus @click="check(scope.row)" type="primary" size="small">执行状态
              </el-button>
              <!-- 有合同隐藏删除，修改记录不管 -->
              <!-- && new Date(scope.row.startTime) > new Date() -->
              <el-button v-focus v-if="!scope.row.maintainContractCode" @click="edit(scope.row)" type="warning"
                size="small">编辑
              </el-button>
              <el-button v-focus v-if="!scope.row.maintainContractCode" @click="del(scope.row.id)" type="danger"
                size="small">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="noticeTotal" :page.sync="searchForm.current" :limit.sync="searchForm.size"
          @pagination="noticePage" />
      </template>
      <div class="nullDate" v-else v-loading="tableLoading">
        <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
      </div>
    </div>

    <!-- <ElevatorInfo
      v-if="executeDialogVisible"
      ref="ElevatorInfo"
    /> -->
    <ExecuteTable v-if="executeDialogVisible" ref="ExecuteTable" @closeExecute="closeExecute" />
    <PlanAdd v-if="planAddVisible" ref="planAddDialog" @closeDialog="closeDialog" />
    <PlanEdit v-if="planAddVisible" ref="planEditDialog" :planInfo="planInfo" @closeDialog="closeDialog" />
    <!-- <PlanAdd v-if="planAddVisible" ref="planAddDialog" :checkType="checkType" :planInfo="planInfo"
      @closeDialog="closeDialog" /> -->
    <!-- <PlanEdit v-if="planAddVisible" ref="planEditDialog" :planInfo="planInfo" @closeDialog="closeDialog" /> -->
  </div>
</template>
<script>
  import Pagination from '../../../components/Pagination'
  // import ElevatorInfo from '../../xxgl/htgl/components/elevatorInfo'
  import ExecuteTable from './components/executeTable';
  import PlanAdd from './components/add'
  import PlanEdit from './components/edit'
  export default {
    components: {
      Pagination,
      // ElevatorInfo,
      ExecuteTable,
      PlanAdd,
      PlanEdit,
    },
    data() {
      return {
        timer: null,
        // 表格参数
        searchForm: {
          current: 1,
          size: 10,
        },
        maintainType: [],
        tableData: [],
        delData: [], // 多选的数据
        // 分页参数
        noticeTotal: 0,
        // dialog参数
        executeDialogVisible: false, // 电梯明细
        planAddVisible: false,
        planInfo: {}, // 计划详情
        checkType: 'add',
        tableLoading: true
      }
    },
    filters: {},
    methods: {
      // 获取字典数据
      getNotice(type, code) {
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {
            console.log('公告', res)
            if (res.data.code === 200) {
              const {
                data: {
                  data
                },
              } = res
              this.maintainType = data.MAINTAIN_TYPE
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      // 分页
      noticePage(data) {
        this.searchForm.current = data.page
        this.searchForm.size = data.limit
        this.onRefer()
      },

      selectable(row, index) {
        if (!row.maintainContractId) {
          return true
        } else {
          return false
        }
      },

      // 弹出框提交
      dioSub(type) {
        this.loading = true
        console.log(this.notice, 'this.notice')
        this.$refs.noticeForm.validate(async (valid) => {
          if (valid) {
            this.noticeForm.status = type === '发布' ? 1 : 0
            console.log(this.noticeForm, 'this.notice')
            this.$http
              .post('/api/system/web/announcement/submit', this.noticeForm)
              .then((res) => {
                console.log(res, '弹出框提交')
                if (res.data.success) {
                  if (type) {
                    this.dialogVisible = false
                    this.$message.success(res.data.msg)
                  }
                  this.onRefer()
                } else {
                  this.$message.error(res.data.msg)
                }
              })
          }
        })
        this.loading = false
      },

      searchOnRefer() {
        this.searchForm.current = 1
        this.onRefer()
      },
      // 重置
      reset() {

        this.searchForm = {
          current: 1,
          size: 10,
        }
        this.onRefer()
      },

      // 获取公告
      onRefer() {
        const data = JSON.parse(JSON.stringify(this.searchForm))
        if (this.searchForm.updateDat && this.searchForm.updateDat.length > 0) {
          data.startTime = this.searchForm.updateDat[0].getTime()
          data.endTime = this.searchForm.updateDat[1].getTime() + 24 * 3600 * 1000 - 1000
        }
        this.tableLoading = true

        this.$http.post('/api/ele/web/maintainPlan/getList', data).then((res) => {

          if (res.data.success) {
            const {
              data: {
                data
              },
            } = res
            this.tableData = data.records ?? []
            this.noticeTotal = data.total ?? 0
            this.$nextTick(() => {
              if (this.noticeTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          } else {
            this.$message.error(res.data.msg)
          }
          this.tableLoading = false
        })
      },

      handleSelectionChange(val) {
        this.delData = val
      },

      // 电梯明细
      check(item) {
        console.log(item)
        this.executeDialogVisible = true
        this.$nextTick(() => {
          this.$refs.ExecuteTable.init(item.id, (refresh) => {
            if (refresh) {}
          })
        })
      },
      closeExecute() {
        this.executeDialogVisible = false
      },
      // 修改公告
      edit(item) {
        console.log(1111111)
        const data = {
          id: item.id,
        }
        this.checkType = 'edit'
        this.$http
          .post('/api/ele/web/maintainPlan/getPlanInfo', data)
          .then((res) => {

            if (res.data.success) {
              const {
                data: {
                  data
                },
              } = res
              this.planAddVisible = true
              this.planInfo = data
              this.$nextTick(() => {
                this.$refs.planEditDialog.init((refresh) => {})
              })

            } else {
              this.$message.error(res.data.msg)
            }
          })
      },

      // 查看电梯明细
      del(item) {
        this.$confirm('此操作将永久删除选中的维保计划, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then(() => {
            let data = {
              planIds: typeof item === 'string' ? [item] : [...item],
            }
            this.$http
              .post('/api/ele/web/maintainPlan/delete', data)
              .then((res) => {
                if (res.data.success) {
                  this.onRefer()
                  this.$message.success(res.data.msg)
                } else {
                  this.$message.error(res.data.msg)
                }
              })
            console.log(item)
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            })
          })
      },

      // 批量删除
      delAll() {
        if (this.delData.length > 0) {
          let arr = []
          this.delData.forEach((qs) => {
            arr.push(qs.id)
          })
          this.del(arr)
        } else {
          this.$message.warning('请选择要删除的维保计划')
        }
      },
      closeDialog() {
        this.planAddVisible = false
        this.onRefer()
      },

      addInfo() {
        console.log(1111111)
        this.planInfo = {}
        this.planAddVisible = true
        this.planInfo = {}
        this.$nextTick(() => {
          this.$refs.planAddDialog.init()
        })
      },
    },
    watch: {},
    created() {
      this.getNotice('公告类型', ['MAINTAIN_TYPE'])
      this.onRefer()
    },
  }
</script>
<style lang="scss" scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .searchBtn {
    margin-left: 15px;
  }
</style>
