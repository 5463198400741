<template>
  <el-dialog
    :title="'新增维保计划'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    append-to-body
    width="70%"
    center
    @close="addPlanDialogClose"
  >
    <div
      style="height: 57vh; overflow-y: scroll; padding-right: 20px"
      class="scrollElement"
    >
      <el-row>
        <!-- 日历 -->
        <el-col :span="12">
          <h3 style="margin: 10px 0;">维保日历</h3>
          <div>
            （请核对每个维保任务的类型和日期，点击维保类型可以修改类型，选择无则取消该项。点击空白日期可新增维保任务。编辑完成后可在右侧进行查看。）
          </div>
          <div class="grid-content bg-purple">
            <FullCalendar
              class="calendar"
              ref="eventDialogue"
              style="height: 100%"
              :options="calendarOptions"
            />
            <!-- </el-form-item> -->
          </div>
        </el-col>
        <el-col :span="12">
          <div style="margin-left: 20px">
            <div class="planTitleBox">
              <h3 style="margin: 10px 0;">任务记录</h3>
              <el-button size="mini" type="primary" @click="checkConflict" >检查冲突任务</el-button>
            </div>
            <div class="planTitleBox" style="margin: 10px 0">
              <div v-for="item in calendarEventsStyle.slice(1).sort((a, b) => a.sort - b.sort)" :key="item.value" class="planItemBox" :style="{'background': item.color}">
                {{ item.label }}({{item.num}}次)
              </div>
            </div>
            <div>
            <el-table ref="multipleTable" :data="calendarEvents" :height="400" style="width: 100%; margin-top: 20px; margin-bottom: 20px">
              <el-table-column align="center"  type="index" label="序号" width="50px" />
              <el-table-column align="center" label="维保计划日期" min-width="200px">
                <template slot-scope="scope">
                  <el-date-picker
                    style="width: 90%"
                    v-model="scope.row.start"
                    :clearable="false"
                    type="date"
                    format="yyyy / MM / dd"
                    placeholder="请选择首次维保日期"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd"
                    @change="changeStart(scope.row)"
                  />
                </template>
              </el-table-column>
              <el-table-column align="center" label="维保类型" min-width="100px" >
                <template slot-scope="scope">
                  <el-select
                      v-model="scope.row.title"
                      placeholder="请选择维保项"
                      filterable
                      @change="changeEvent(scope.row)"
                      style="width: 90%; margin: 0 15px">
                      <el-option v-for="item in eventChoose.slice(1)" :key="item.value" :label="item.name"
                        :value="item.name" />
                    </el-select>
                </template>
              </el-table-column>
              <el-table-column align="center" label="是否冲突" min-width="80px" >
                <template slot-scope="scope">
                  <span>{{ scope.row.isConflict == 1  ? '是' : '否'}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" fixed="right" prop="address" label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button @click="del(scope.row)" type="danger"
                    size="small">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            </div>
          </div>
        </el-col>
      </el-row>

      <div :style="{left:sLeft+'px',top:sTop+'px'}" v-show="isCarte" class="contextmenu">
				<ul class="contextmenus">
					<li v-for="item in eventChoose" :key="item.value" @click="editEvent(item.value)">
            {{ item.name }}
          </li>
				</ul>
			</div>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <div style="text-align: center;">
        <el-button
          type="info"
          @click="addPlanDialogClose"
        >上一步</el-button>
        <el-button
          type="primary"
          @click="addPlanConfirm"
        >确认提交</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid' // 日期
import interactionPlugin from '@fullcalendar/interaction' // 日期点击事件
import { formatDate } from '@/util';


export default {
  components: {
    FullCalendar,
  },
  props: ['planFormData'],

  data() {
    return {
      year: '', //年份
      month: '', //月份
      loading: true,
      visible: true,
      calendarApi: null,
      timeList: [15, 30, 90, 365, 180],
      //选中日期
      eventChooseData: '',
      //事件下拉列表
      eventChoose: [],
       //日历事件样式
      calendarEventsStyle: [{
          label: '无',
          value: 0,
          color: '#f00',
          sort: 1
        }, {
          label: '半月保',
          value: 1,
          color: '#0dc160',
          sort: 2
        }, {
          label: '月度保',
          value: 2,
          color: '#63a103',
          sort: 3
        }, {
          label: '季度保',
          value: 3,
          color: '#02a7f0',
          sort: 4
        }, {
          label: '年度保',
          value: 4,
          color: '#a36717',
          sort: 6
        }, {
          label: '半年保',
          value: 5,
          color: '#8080ff',
          sort: 5
        }],
      planDetail: {
        1:{},
        2:{},
        3:{},
        5:{},
        4:{},
      },
      // 日历下拉参数
      sLeft: 0,
      sTop: 0,
      isCarte: false,
      eventsListTime: [],
      isConfirm: false,

      // 表格参数
      planForm: {},
      calendarEvents: [],
      // 日历参数
      calendarOptions: {
        height: 400,
        plugins: [dayGridPlugin, interactionPlugin],
        editable: false,
        selectable: true,
        navLinks: false,
        handleWindowResize: true, // 是否随窗口大小变化
        initialView: 'dayGridMonth', // 设置默认显示月，可选周、日
        // select: this.handleDateSelect,

        timeZone: 'local',

        dateClick: this.handleDateClick, // 日期点击
        eventClick: this.handleEvents, // 事件点击
        events: this.getCalendarEvents, // 设置日程
        locale: 'zh', // 设置语言
        headerToolbar: {
          left: 'prevYear,prev,today',
          center: 'title',
          right: 'next,nextYear',
        },
        customButtons: {
          prevYear: {
            text: 'prevYear',
            click: () => {
              this.prevYear()
            },
          },
          prev: {
            text: 'prev',
            click: () => {
              this.prev()
            },
          },
          nextYear: {
            text: 'nextYear',
            click: () => {
              this.nextYear()
            },
          },
          next: {
            text: 'next',
            click: () => {
              this.next()
            },
          },
          today: {
            text: 'today',
            click: () => {
              this.todayClick()
            },
          },
        },
      },
    }
  },

  computed:{
      pickerOptions() {
          return {
              disabledDate:(time) =>{
                  let date= formatDate(time, 'yyyy-MM-dd')
                  return this.eventsListTime.includes(date)
              }
          }
      },
  },

  created() {
  },
  mounted() {
    this.$nextTick(() => {
      this.year = new Date()
      this.month = new Date()
      this.planForm = this.planFormData
      console.log(this.planFormData.maintenanItemData,'this.planFormData.maintenanItemData');
      this.eventChoose = this.planFormData.maintenanItemData.filter(item => item.type)
      console.log(this.eventChoose,'tthis.eventChoose');
      this.eventChoose.unshift({
        type: false,
        maintainItemId: '',
        num: '',
        name:'无',
        value: 0
      })
      this.getEvent()
    })
  },

  methods: {
    // 回到今天
    todayClick() {
      this.$refs.eventDialogue.getApi().today()
    },
    // 前一年
    prevYear(event) {
      this.$refs.eventDialogue.getApi().prevYear()
    },
    // 前一个月
    prev(event) {
      this.$refs.eventDialogue.getApi().prev()
    },
    // 下一个月
    next(event) {
      this.$refs.eventDialogue.getApi().next()
    },
    // 下一年
    nextYear(event) {
      this.$refs.eventDialogue.getApi().nextYear()
    },

    // 初始化日历事件
    getCalendarEvents(info, successCallback, failureCallback) {
      const events = [...this.calendarEvents]
      successCallback(events)
    },

    // 将首次维保置前
    getNewMaintenanItemData(){
      let temporaryArry = []
      let data = JSON.parse(JSON.stringify(this.planFormData.maintenanItemData))
      data = data.sort((a, b) => b.value - a.value)
      // let currentIdx = data.findIndex(v => v.value == this.planFormData.isFirst);
      // temporaryArry.push(data[currentIdx])
      // data.splice(currentIdx, 1);
      //重新渲染数组
      data = temporaryArry.concat(data);
      return data
    },

    //获取日历事件
    getEvent(){
      let starTime = new Date(this.planFormData.firstMaintainTime).getTime()
      let newMaintenanItemData = this.getNewMaintenanItemData()
      console.log(newMaintenanItemData,'newMaintenanItemData');
      // newMaintenanItemData = newMaintenanItemData.sort((a, b) => b.value - a.value)
      newMaintenanItemData.map((item, ItemDataIndex) => {
        if (item.type) {
          for (let index = 0; index < item.num; index++) {
            let starTimeNew = formatDate(new Date(starTime + index * this.timeList[item.value - 1] * 24 * 3600 * 1000), 'yyyy-MM-dd')
            let isSplice = this.calendarEvents.find(eventsItem => eventsItem.start == starTimeNew)
            if (isSplice) {
              // if (item.value == this.planFormData.isFirst) {
              //   isSplice.title = this.calendarEventsStyle[this.planFormData.isFirst].label
              //   isSplice.backgroundColor = this.calendarEventsStyle[this.planFormData.isFirst].color
              // } else {
              //   let newEvent = this.getNewEvent(item, starTimeNew)
              //   this.calendarEvents.push(newEvent)
              // }
              if (item.value < isSplice.value) {
                // isSplice.title = this.calendarEventsStyle[this.planFormData.isFirst].label
                // isSplice.backgroundColor = this.calendarEventsStyle[this.planFormData.isFirst].color
              } else {
                let newEvent = this.getNewEvent(item, starTimeNew)
                this.calendarEvents.push(newEvent)
              }
            } else {
              this.calendarEvents.push({
                title: this.calendarEventsStyle[item.value].label,
                start: starTimeNew,
                allDay: true,
                choose: true,
                backgroundColor: this.calendarEventsStyle[item.value].color,
                maintenanItem: item.value,
                isConflict: 0
              })
            }
          }
        }
      })
      this.getNewEventnumber()
      this.$refs.eventDialogue.getApi().refetchEvents()
    },

    // 延后同时间事件
    getNewEvent(itemTypeInfo, timeNew){
      let starTimeBack = this.getNewStarTime(itemTypeInfo.value, timeNew)
      let newEvent = {
        title: this.calendarEventsStyle[itemTypeInfo.value].label,
        start: starTimeBack,
        allDay: true,
        choose: true,
        backgroundColor: this.calendarEventsStyle[itemTypeInfo.value].color,
        maintenanItem: itemTypeInfo.value,
        isConflict: 0
      }

      return newEvent
    },

    // 获取延后日期
    getNewStarTime(type, time){
      let newTime = ''
      if (this.calendarEvents.find(eventsItem => eventsItem.start == time)) {
        let dateTime = formatDate(new Date(new Date(time).getTime() + this.timeList[type - 1] * 24 * 3600 * 1000), 'yyyy-MM-dd')
        newTime = this.getNewStarTime(type, dateTime)
      } else {
        newTime = time
      }
      return newTime
    },

    changeStart(row){
      console.log(row,this.eventsListTime,'选择')
      this.getNewEventnumber()
      this.$refs.eventDialogue.getApi().refetchEvents()
    },

    // 改变维保类型
    changeEvent(data){
      let type = this.eventChoose.find(item => item.name == data.title).value
      this.eventChooseData = new Date(data.start)
      this.editEvent(type)
    },

    //列表删除事件
    del(row){
      this.eventChooseData = new Date(row.start)
      this.editEvent(0)
    },

    // 日历事件下拉
    openMenu(e, tag ,index) {
      this.isCarte = true
      this.sLeft = e.pageX
      this.sTop = e.pageY
    },

    // 计算维保次数
    getNewEventnumber(){
      this.eventsListTime = []
      this.calendarEventsStyle = this.calendarEventsStyle.map(item => {
        item.num = this.calendarEvents.filter(events => events.title == item.label).length
        return item
      })
      this.calendarEvents.map(item => {
        this.eventsListTime.push(item.start)
      })
    },

    // 修改事件
    editEvent(index){
      this.isCarte = false
      let eventTime = formatDate(this.eventChooseData, 'yyyy-MM-dd')
      let calendarIndex = this.calendarEvents.findIndex(item => item.start == eventTime)
      if (index > 0) {
        let newEvent = {
          title: this.calendarEventsStyle[index].label,
          start: eventTime,
          allDay: true,
          choose: true,
          backgroundColor: this.calendarEventsStyle[index].color,
          maintenanItem: this.calendarEventsStyle[index].value,
          isConflict: calendarIndex > -1 ? this.calendarEvents[calendarIndex].isConflict : 0
        }
        console.log(calendarIndex,'修改');
        if (calendarIndex > -1) {
          this.calendarEvents.splice(calendarIndex, 1, newEvent)
        } else {
          this.calendarEvents.push(newEvent)
        }
      } else {
        console.log('删除');
        this.calendarEvents.splice(calendarIndex, 1)
      }
      this.getNewEventnumber()
      this.$refs.eventDialogue.getApi().refetchEvents()
    },

    // 日程事件点击
    handleEvents (info) {
      this.openMenu(info.jsEvent)
      this.eventChooseData = info.el.fcSeg.eventRange.range.start
      // this.currentEvents = events
    },
    // 日期点击
    handleDateClick (selectInfo) {
      console.log(selectInfo,'selectInfo');
      this.openMenu(selectInfo.jsEvent)
      this.eventChooseData = selectInfo.dateStr
        // if (!this.calendarEvents.find(item => item.start == selectInfo.dateStr)) {
        //   this.calendarEvents.push({
        //     title: this.calendarEventsStyle[this.planFormData.isFirst].label,
        //     start: selectInfo.dateStr,
        //     allDay: true,
        //     choose: true,
        //     backgroundColor: this.calendarEventsStyle[this.planFormData.isFirst].color,
        //     maintenanItem: this.calendarEventsStyle[this.planFormData.isFirst].value,
        //   })
        // }
        // this.getNewEventnumber()
        // this.$refs.eventDialogue.getApi().refetchEvents()
        // 父组件直接调用子组件方法
        // this.$refs['eventDialogue'].openDialog('add')
        // 父组件直接修改子组件变量
        // this.$refs['eventDialogue'].dialogVisible = true
      // }
    },

    // 选择维保类型弹窗
    handleDateSelect(data) {
        this.calendarEvents.push({
          title: '维保项',
          start: data.start,
          allDay: true,
          choose: true,
        })
        this.$refs.eventDialogue.getApi().refetchEvents()
    },

    // 检查冲突任务
    checkConflict(type){
      // let data = ['2024-09-05', '2024-10-05']
      console.log(this.calendarEvents,'this.calendarEvents');
      let taskList = []
      this.calendarEvents.map(item => {
        taskList.push({
          startTime: new Date(item.start).getTime()
        })
      })
      let data = {
        eleIds: this.planFormData.eleIds,
        taskList
      }
      this.$http
        .post('/api/ele/web/maintainPlan/checkConflict', data)
        .then((res) => {
          if (res.data.code === 200) {
            this.calendarEvents.map(item => {
              item.isConflict = 0
            })
            if (type) {
                const {id, planName, eleIds, firstMaintainTime, maintenanItemData } = this.planFormData
                let taskList = []
                this.calendarEvents.map(item => {
                  taskList.push({
                    maintainType: item.maintenanItem,
                    startTime: new Date(item.start).getTime()
                  })
                })
                let newMaintenanItemData = maintenanItemData.sort((a, b) => a.value - b.value)
                for (var key in this.planDetail) {
                  // 以字符串作为键名
                  this.planDetail[key] = {
                    maintainItemId: newMaintenanItemData[key - 1].type ? newMaintenanItemData[key - 1].maintainItemId : '',
                    num: newMaintenanItemData[key - 1].type ? this.calendarEventsStyle[key].num : 0,
                    isFirst: this.planFormData.isFirst == key
                  }
                }
                const data = {
                  planName,
                  eleIds,
                  firstMaintainTime: new Date(firstMaintainTime).getTime(),
                  planDetail: JSON.stringify(this.planDetail),
                  taskList
                }
                let url = this.planFormData.id ? '/api/ele/web/maintainPlan/editNewPlan' : '/api/ele/web/maintainPlan/addNewPlan'
                data.id = id ?? ''
                this.$http
                  .post(url, data)
                  .then((res) => {
                    if (res.data.code === 200) {
                      this.loading = false
                      this.visible = false
                      this.$emit('nextPlanClose', 'success')
                      this.$message.success(res.data.msg)
                    } else {
                      this.$message.error(res.data.msg)
                    }
                  })
                this.isConfirm = false
                this.loading = false
            }
          } else {
            const {
              data: {
                data
              },
            } = res
            this.calendarEvents.map(item => {
              console.log(item,'item');
              if (data.find(dataItem => dataItem.split(' ')[0] == item.start)) {
                item.isConflict = 1
              }
            })
            this.$message.error(res.data.msg)
          }
        })
    },


    // 保存计划
    async addPlanConfirm() {
      this.loading = true
      // this.isConfirm = true
      this.checkConflict('isConfirm')
    },



    addPlanDialogClose() {
      this.visible = false
      this.$emit('nextPlanClose')
    },
  },
}
</script>

<style lang="scss" scoped>
.grid-content{
  margin-top: 10px;
}
.contextmenu{
  height: auto
}
.addPlanForm {
  padding-right: 35px;
}
.planTitleBox{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.planItemBox{
  padding: 3px;
  color: #fff
}
/deep/.el-form-item {
  margin-bottom: 32px;
}
/deep/.el-input-group__prepend {
  width: 40%;
}
>>> .el-input-group__append {
  background: #409eff;
  color: #fff;
}
>>> .el-input-group__prepend {
  width: 25%;
  padding: 0px;
  background: #409eff;
  .el-select {
    margin: -1px;
    .el-input__inner {
      color: #fff !important;
    }
    ::-webkit-input-placeholder {
      color: #fff;
    }
  }
}
.datepicker {
  >>> .el-form-item__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
